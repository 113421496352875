// This is the scss entry file
import "../styles/index.scss";

// We can import Bootstrap JS instead of the CDN link, if you do not use
// Bootstrap, please feel free to remove it.
// import "bootstrap/dist/js/bootstrap.bundle";
//import "bootstrap/js/dist/dropdown";
import {Dropdown} from "bootstrap";


// We can import other JS file as we like
// import "../components/hero";
import "../components/navbar";
import "../components/splide";
import "../components/schedule";

import Toastify from "toastify-js";

if (/Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
  document.body.classList.add('js-mobile');
} else {
  var MobileDetect = require('mobile-detect'),
  md = new MobileDetect(navigator.userAgent, -1);
  if (md.mobile()) {
      document.body.classList.add('js-mobile');
  }
}

window.document.addEventListener("DOMContentLoaded", function () {
  const body = window.document.body;

  if (body.classList.contains('js-is-production')) {
    const dropdownElementList = document.querySelectorAll('.dropdown-toggle');
    for (let i = 0; i < dropdownElementList.length; i++) {
      new Dropdown(dropdownElementList[i]);
    }
  }

  window.ticket_added = function (lang) {
    Toastify({
      text: lang === 'en' ? "Added to basket":"Vloženo do košíku",
      duration: 2000,
      position: "center",
      stopOnFocus: false,
      style: {
        background: "linear-gradient(to right, #009245, #45B649)",
        padding: "1rem 2rem",
        fontWeight: "bold"
      },
      offset: {
        y: 90
      },
    }).showToast();
  };

  // window.console.log("dom ready");
});
